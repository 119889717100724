

































import { DictItem, getDictConfig } from '@/api/other';
import { Vue, Component, Ref } from 'vue-property-decorator';
import HeaderSearch from '@/components/Base/HeaderSearch/index.vue';
import BaseList from '@/components/Base/BaseList/index.vue';
import ProductCard from '@/components/Base/ProductCard/index.vue';
import { getProList } from '@/api/product';
@Component({
  name: '',
  components: { HeaderSearch, BaseList, ProductCard }
})
export default class extends Vue {
  searchValue = '';
  // 热门搜索
  hotSearch: DictItem[] = [];
  // 搜索历史记录
  searchHistory: string[] = [];
  /* 搜索过 不展示搜索词条 */
  listShow = false;

  @Ref('myList')
  myList!: BaseList;

  curCatId = '';

  get catId() {
    return this.$route.query.catId;
  }

  get pageName() {
    return this.$route.query.name;
  }

  get api() {
    return getProList;
  }

  get param() {
    return {
      spuName: this.searchValue,
      catId: this.catId
    };
  }

  onClear() {
    this.listShow = false;
  }

  onSearch(val = '') {
    this.searchValue = val;
    this.listShow = true;
    this.myList.reload();
    this.setSearchHistory(this.searchValue);
  }

  onCancel() {
    this.$router.go(-1);
  }

  getSearchHistory() {
    const value = localStorage.getItem('searchHistory');
    this.searchHistory = value ? JSON.parse(value) : [];
  }

  setSearchHistory(v: string) {
    if (!v) return;
    localStorage.setItem('searchHistory', JSON.stringify([...new Set(this.searchHistory.concat(v))]));
    this.getSearchHistory();
  }

  removeSearchHistory() {
    localStorage.removeItem('searchHistory');
    this.getSearchHistory();
  }

  // 获取热门搜索
  async getHotList() {
    const { payload } = await getDictConfig('HOT_WORD');
    this.hotSearch = payload;
  }

  created() {
    this.getHotList();
    this.getSearchHistory();
  }

  activated() {
    if (this.catId && this.curCatId != this.catId) {
      this.curCatId = this.catId as string;
      this.myList.list = [];
      this.onSearch();
    }
    if (this.pageName && typeof this.pageName === 'string') document.title = this.pageName;
  }
}
